import React, { useMemo, useState, FC } from 'react'
import { Button, Flex, FormControl, FormLabel, useToast } from '@chakra-ui/react'
import { useAppContext } from '@contexts/AppProvider'
import { useAxios } from '@contexts/AxiosProvider'
import { useModal } from '@contexts/ModalProvider'
import {
  getPatientProperty,
  getPatientName,
  getPatientDob,
  getPatientIdentifier,
  findPatientProperty,
  createPatient,
  getPatinetExtension
} from 'utils/patient'
import { formatDate } from 'utils/date'
import { NHS_FHIR_CODE, PMI_ID_FHIR_CODE, TAGS_FHIR_URL } from 'constants/fhir'
import { SelectFormControlProps } from '@components/Form/SelectFormControl'
import { countries } from 'constants/countries'
import axios, { AxiosError } from 'axios'
import { CustomFieldType } from '@components/Form/CustomFormController'
import { CustomController } from '@components/Form'
import AsyncSelect from 'react-select/async'
import { debounce } from 'lodash'
import { NEXT_PUBLIC_GET_ADDRESS_IO_API_KEY } from 'constants/appConstants'
import {
  PatientFormValues,
  DataErrorObject,
  CustomPatient,
  AddressSuggestionType
} from 'types/user'

interface AddModalProps {
  firebaseUserId: string
  refreshUserData: () => void
  refreshPatientData: () => void
  employeeData: { entry: { resource: CustomPatient }[] }
}

const EditPatient: FC<AddModalProps> = ({
  firebaseUserId,
  refreshPatientData,
  refreshUserData,
  employeeData
}) => {
  const toast = useToast()
  const { isGP, isClinician } = useAppContext()

  const formFields = [
    [
      {
        id: 'firstName',
        label: 'First Name',
        placeholder: 'Joe',
        type: 'text',
        isRequired: true
      },
      {
        id: 'middleName',
        label: 'Middle Name',
        placeholder: 'Andrew',
        type: 'text',
        isRequired: false
      },
      {
        id: 'lastName',
        label: 'Last Name',
        placeholder: 'Doe',
        type: 'text',
        isRequired: true
      }
    ],
    [
      {
        id: 'birthDate',
        label: 'Date of Birth',
        placeholder: '',
        type: 'date',
        isRequired: true
      },
      {
        id: 'gender',
        label: 'Gender',
        placeholder: 'Select gender',
        type: 'select',
        isRequired: true,
        options: [
          {
            value: 'male',
            label: 'Male'
          },
          {
            value: 'female',
            label: 'Female'
          }
        ]
      }
    ],
    [
      {
        id: 'phoneNumber',
        label: 'Mobile',
        placeholder: '+44 123 456 789',
        type: 'tel',
        isRequired: true
      },
      {
        id: 'email',
        label: 'Email',
        placeholder: 'artem@qured.com',
        type: 'email',
        isRequired: true
      }
    ],
    [
      {
        id: 'address-lookup',
        label: 'Lookup Address',
        type: 'text',
        placeholder: ''
      }
    ],
    [
      {
        id: 'address1',
        label: 'Address 1',
        placeholder: '123 Fake Street',
        type: 'text',
        isRequired: true
      },
      {
        id: 'address2',
        label: 'Address 2',
        placeholder: 'Flat 1',
        type: 'text',
        isRequired: false
      }
    ],
    [
      {
        id: 'city',
        label: 'City',
        placeholder: 'London',
        type: 'text',
        isRequired: true
      },
      {
        id: 'postcode',
        label: 'Post Code',
        placeholder: 'SW1A 1AA',
        type: 'text',
        isRequired: true
      }
    ],
    [
      {
        id: 'country',
        label: 'Country',
        placeholder: 'Select country',
        type: 'select',
        isRequired: true,
        options: countries.map((country) => ({
          value: country.name,
          label: country.name
        }))
      }
    ]
  ] as CustomFieldType[][]

  const additionalFormFields = [
    [
      {
        id: 'insuranceCompanyNumber',
        label: 'PMI Company Name',
        placeholder: 'Signa',
        type: 'text',
        disabled: true,
        isRequired: false
      },
      {
        id: 'insurancePlanName',
        label: 'PMI Plan Name',
        placeholder: 'Full Coverage',
        type: 'text',
        disabled: true,
        isRequired: false
      }
    ],
    [
      {
        id: 'insuranceMemberId',
        label: 'PMI Member Number',
        placeholder: '123456789',
        type: 'text',
        isRequired: false
      },
      {
        id: 'nhsNumber',
        label: 'NHS Number',
        placeholder: ' 485 777 3456',
        type: 'text',
        isRequired: false
      }
    ]
  ]

  const formFieldsConcatenated =
    isGP || isClinician ? [...formFields, ...additionalFormFields] : formFields

  const defaultValues: PatientFormValues = {
    firstName: '',
    middleName: '',
    lastName: '',
    birthDate: '',
    gender: 'male',
    phoneNumber: '',
    email: '',
    city: '',
    postcode: '',
    address1: '',
    address2: '',
    country: 'United Kingdom',
    insuranceCompanyNumber: '',
    insurancePlanName: '',
    insuranceMemberId: '',
    nhsNumber: ''
  }

  const employee = employeeData?.entry?.[0]?.resource

  const employeeDetails = useMemo(() => {
    if (!employee) return null
    const { firstName, middleName, lastName } = getPatientName(employee)
    const birthDate = getPatientDob(employee) || ''
    return {
      firstName,
      middleName,
      lastName,
      birthDate: formatDate(
        new Date(birthDate),
        {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        },
        'fr-CA'
      ),
      gender: getPatientProperty(employee, 'gender') || '',
      phoneNumber: findPatientProperty(employee, 'telecom', ['system', 'phone']) || '',
      email: findPatientProperty(employee, 'telecom', ['system', 'email']) || '',
      tags: getPatinetExtension(employee, TAGS_FHIR_URL)?.split(',') || [],
      city: getPatientProperty(employee, 'address[0].city') || '',
      address1: getPatientProperty(employee, 'address[0].line[0]') || '',
      address2: getPatientProperty(employee, 'address[0].line[1]') || '',
      postcode: getPatientProperty(employee, 'address[0].postalCode') || '',
      country: getPatientProperty(employee, 'address[0].country') || '',
      insuranceMemberId: getPatientIdentifier(employee, PMI_ID_FHIR_CODE) || '',
      nhsNumber: getPatientIdentifier(employee, NHS_FHIR_CODE) || ''
    }
  }, [employee]) as PatientFormValues

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formValues, setFormValues] = useState<PatientFormValues>(employeeDetails || defaultValues)
  const { axiosInstanceAccountService } = useAxios()
  const { closeModal } = useModal()

  const onSubmit = async (e: React.SyntheticEvent) => {
    setIsSubmitting(true)
    e.preventDefault()
    let payload
    if (employee) {
      const formattedEmployee = employee as Partial<CustomPatient>
      delete formattedEmployee.user
      delete formattedEmployee.coverage
      const updatedPatient = createPatient({
        employee,
        updatedEmployeeValues: { ...formValues }
      })
      payload = { patient: updatedPatient }
    }

    try {
      await axiosInstanceAccountService.patch(`/users/${firebaseUserId}`, payload)
      refreshPatientData()
      refreshUserData()
      closeModal()
      toast({
        title: 'Patient edited successfully',
        status: 'success',
        duration: 3000,
        isClosable: true
      })
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { response } = error as AxiosError
        const { data } = response as { data: DataErrorObject }
        toast({
          title: 'Error editing Patient',
          description: `${data.message || error.message}`,
          status: 'error',
          duration: 4000,
          isClosable: true
        })
      } else {
        toast({
          title: 'Error editing Patient',
          description: 'Unhandled error',
          status: 'error',
          duration: 4000,
          isClosable: true
        })
      }
      console.error('components/Modal/Employee/Add.tsx#Submit', { error })
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { id, value } = e.target
    const newFormValues = {
      ...formValues,
      [id]: value
    }
    setFormValues(newFormValues)
  }

  const getAddress = async (inputValue: string) => {
    if (!inputValue) return []

    const response = await fetch(
      `https://api.getAddress.io/autocomplete/${inputValue}?api-key=${NEXT_PUBLIC_GET_ADDRESS_IO_API_KEY}&all=true`
    )

    if (response.status === 200) {
      const data = await response.json()
      return data.suggestions.map((item: AddressSuggestionType) => ({
        value: item.id,
        label: item.address
      }))
    } else {
      return []
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const debouncedGetAddress = debounce((inputValue: string, callback: any) => {
    getAddress(inputValue)
      .then(callback)
      .catch((error) => {
        console.error('Error fetching address options:', error)
      })
  }, 500)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const loadOptions = (inputValue: string, callback: (options: any) => void) => {
    debouncedGetAddress(inputValue, callback)
  }

  const fetchAndFillAddress = async (addressId: string) => {
    const response = await fetch(
      `https://api.getAddress.io/get/${addressId}?api-key=${NEXT_PUBLIC_GET_ADDRESS_IO_API_KEY}`
    )

    if (response.status === 200) {
      const data = await response.json()
      const newFormValues = {
        ...formValues,
        address1: data.line_1,
        address2: data.line_2,
        city: data.town_or_city,
        postcode: data.postcode,
        country: data.country
      }

      setFormValues(newFormValues)
    } else {
      console.error('Error fetching address details:', response.statusText)
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleAddressChange = (selectedOption: any) => {
    fetchAndFillAddress(selectedOption.value)
  }

  return (
    <form onSubmit={onSubmit}>
      <Flex flexDirection="column" gap={4}>
        {formFieldsConcatenated.map((row) => (
          <Flex key={`row ${row[0].id}`} gap={4}>
            {row.map((field) => {
              const selectField = field as SelectFormControlProps
              return field.type !== 'select' ? (
                field.id === 'address-lookup' ? (
                  <FormControl w="100%">
                    <FormLabel>{field.label}</FormLabel>
                    <AsyncSelect
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          width: '100%'
                        })
                      }}
                      loadOptions={loadOptions}
                      placeholder="W11 3LF"
                      onChange={handleAddressChange}
                    />
                  </FormControl>
                ) : (
                  <CustomController
                    key={field.id}
                    field={field}
                    value={formValues[field.id as keyof PatientFormValues]}
                    handleChange={handleChange}
                  />
                )
              ) : (
                <CustomController
                  key={selectField.id}
                  field={selectField}
                  value={formValues[selectField.id as keyof PatientFormValues]}
                  handleChange={handleChange}
                />
              )
            })}
          </Flex>
        ))}
        <Button type="submit" mt={4} disabled={isSubmitting} isLoading={isSubmitting}>
          {'Edit'}
        </Button>
      </Flex>
    </form>
  )
}

export default EditPatient
