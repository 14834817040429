import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  ReactNode,
  FC,
  useCallback,
  Dispatch,
  SetStateAction
} from 'react'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/nextjs'
import { isBrowser } from '@utils/isBrowser'

// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';
import { initializeApp } from 'firebase/app'
import { getAuth, signInWithEmailAndPassword, User, Auth, UserInfo } from 'firebase/auth'
import { RoleType, Role } from 'types/role'
import { isQuredEmail } from '@utils/mics'

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
}

// Initialize Firebase Authentication and get a reference to the service
const app = initializeApp(firebaseConfig)

interface Props {
  children: ReactNode
}
interface CustomUserInfo extends UserInfo {
  customAttributes?: string
}

interface CustomUser extends User {
  reloadUserInfo?: CustomUserInfo
}

interface AuthContextValue {
  user: CustomUser | null
  roles: Role[] | null
  setRoles: Dispatch<SetStateAction<Role[] | null>>
  activeRole: Role | null
  setActiveRole: Dispatch<SetStateAction<Role | null>>
  login: ({ email, password }: { email: string; password: string }) => void
  logout: () => void
  isAuthenticated: boolean
  isLoading: boolean
  error: string
  auth: Auth
  firebaseAccessToken: string | null
  getUserFirebaseToken: () => void
}

export const AuthContext = createContext({} as AuthContextValue)

const AuthProvider: FC<Props> = ({ children }) => {
  const rolesFromlocalStorage =
    (isBrowser() && JSON.parse(localStorage.getItem('roles') || '[]')) || null
  const [user, setUser] = useState<CustomUser | null>(null)
  const [roles, setRoles] = useState<Role[] | null>(rolesFromlocalStorage)
  const [activeRole, setActiveRole] = useState<Role | null>(null)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const router = useRouter()
  const auth = getAuth(app)
  const [firebaseAccessToken, setFirebaseAccessToken] = useState<string | null>(
    (isBrowser() && localStorage.getItem('firebaseAccessToken')) || null
  )

  const isAuthorized = useCallback(({ roles }: { roles: Role[] }): boolean => {
    const authorizedRoles = [
      RoleType.GPPractitioner,
      RoleType.Clinician,
      RoleType.HealthAdvisor,
      RoleType.SuperAdmin,
      RoleType.Super,
      RoleType.ResourceManager
    ]
    let authorized = false
    const rolesTopPush: Role[] = []
    for (const role of roles) {
      if (authorizedRoles.includes(role.code as RoleType)) {
        authorized = true
        rolesTopPush.push(role)
      }
    }
    setRoles(rolesTopPush)
    localStorage.setItem('roles', JSON.stringify(rolesTopPush))
    return authorized
  }, [])

  const getUserFirebaseToken = useCallback(async () => {
    if (user) {
      const token = await user.getIdToken(true)
      localStorage.setItem('firebaseAccessToken', token)
      setFirebaseAccessToken(token)
    }
  }, [user])

  const resetAuth = useCallback(() => {
    setUser(null)
    setIsAuthenticated(false)
    setIsLoading(false)
    setError('')
    setRoles(null)
    Sentry.setUser(null)
    localStorage.removeItem('firebaseAccessToken')
    localStorage.removeItem('roles')
  }, [])

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const customUser = user as CustomUser
        const roles = customUser?.reloadUserInfo?.customAttributes
          ? (JSON.parse(customUser?.reloadUserInfo?.customAttributes)?.roles as Role[])
          : []
        if (!isAuthorized({ roles })) {
          resetAuth()
          return
        }
        setIsLoading(false)
        setIsAuthenticated(true)
        setUser(user)
        setError('')
        await getUserFirebaseToken()
        Sentry.setUser({ user })
      } else {
        resetAuth()
      }
    })
  }, [auth, resetAuth, getUserFirebaseToken, isAuthorized])

  useEffect(() => {
    const handle = setInterval(async () => {
      await getUserFirebaseToken()
      //set interval to run for every 30 minutes
    }, 30 * 60 * 1000)

    // clean up setInterval
    return () => clearInterval(handle)
  }, [getUserFirebaseToken])

  const login = useCallback(
    ({ email, password }: { email: string; password: string }) => {
      if (!isQuredEmail({ email })) {
        setError('You are not authorized to access this application')
        return
      }

      setIsLoading(true)
      setIsAuthenticated(false)
      signInWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          // Signed in
          const user = userCredential.user as CustomUser
          const roles = user?.reloadUserInfo?.customAttributes
            ? (JSON.parse(user?.reloadUserInfo?.customAttributes)?.roles as Role[])
            : []
          if (!isAuthorized({ roles })) {
            setError('You are not authorized to access this application')
            setIsAuthenticated(false)
            setIsLoading(false)
            return
          }
          await getUserFirebaseToken()
          setUser(user)
          Sentry.setUser({ user })
          setIsAuthenticated(true)
          setIsLoading(false)
          setError('')
          router.push('/appointment')
        })
        .catch((error) => {
          const errorCode = error.code
          const errorMessage = error.message
          console.error('contexts/AuthProvider.tsx#signInWithEmailAndPassword', {
            errorCode,
            errorMessage,
            error
          })
          setError(errorMessage)
          setIsAuthenticated(false)
          setIsLoading(false)
        })
    },
    [auth, getUserFirebaseToken, router, isAuthorized, setUser]
  )

  const logout = () => {
    auth.signOut().then(() => {
      resetAuth()
      router.push('/login')
    })
  }

  const value = {
    user,
    roles,
    setRoles,
    activeRole,
    setActiveRole,
    login,
    logout,
    isAuthenticated,
    isLoading,
    error,
    auth,
    firebaseAccessToken,
    getUserFirebaseToken
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
