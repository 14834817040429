export enum TableHeaderEnum {
  PatientID = 'Patient ID',
  SampleStatus = 'Sample Status',
  ReportStatus = 'Report Status',
  FirstName = 'First Name',
  LastName = 'Last Name',
  PhoneNumber = 'Phone Number',
  DOB = 'DOB',
  Gender = 'Gender',
  Email = 'Email',
  Allow_Any_Clinician = 'Allow Any Clinician',
  TestName = 'Test Name',
  TestSentDate = 'Test Sent Date',
  TestResultDate = 'Test Result Date',
  Action = 'Action',
  ResultReviewedDate = 'Result Reviewed Date',
  OrganizationName = 'Organization Name',
  AppointmentStatus = 'Appointment Status',
  AppointmentDate = 'Appointment Date'
}

export enum SampleStatusEnum {
  PartialResults = 'Partial Results',
  FullResults = 'Full Results'
}

export enum ReportStatusEnum {
  Normal = 'Normal',
  Abnormal = 'Abnormal'
}

export const ResultsColorMap: Record<string, string> = {
  [SampleStatusEnum.PartialResults]: '#F5A623',
  [SampleStatusEnum.FullResults]: '#30C06D',
  [ReportStatusEnum.Normal]: '#30C06D',
  [ReportStatusEnum.Abnormal]: 'red',
  inherit: 'inherit'
}
