import React from 'react'
import styles from './Sidebar.module.css'
import {
  List,
  ListItem,
  Box,
  Flex,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerCloseButton,
  DrawerHeader
} from '@chakra-ui/react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { BlueLine } from '@components/Misc'
import { CommonIcon } from '@components/Icon/CommonIcon'
import { useAppContext } from '@contexts/AppProvider'
import Image from 'next/image'
import cn from 'classnames'

interface NavItem {
  href: string
  label: string
  icon: string
}

const NavItem = ({ href, label, icon }: NavItem) => {
  const { pathname } = useRouter()
  const { setSidebarOpen } = useAppContext()
  const selected = pathname === href
  const linkClassName = cn(styles.navItem, selected && styles.selected)
  const iconWrapperClassName = cn(selected && styles.selected)

  return (
    <>
      <ListItem
        position="relative"
        display="flex"
        _hover={{ color: 'var(--chakra-colors-quredBlue-600)' }}
        onClick={() => {
          setSidebarOpen(false)
        }}
      >
        {selected && (
          <Box position="absolute" top={-2}>
            <BlueLine width={4} height={40} />
          </Box>
        )}
        <Flex ml={6} mr={3} className={iconWrapperClassName}>
          <CommonIcon name={icon} size={24} />
        </Flex>
        <NextLink href={href} passHref>
          <a className={linkClassName}>{label}</a>
        </NextLink>
      </ListItem>
    </>
  )
}

export const Sidebar = () => {
  const { isGP, isClinician, isResourceManager, isSuperAdmin, sidebarOpen, setSidebarOpen } =
    useAppContext()

  const navItems = [
    {
      href: '/appointment',
      label: 'Appointments',
      icon: 'calendar'
    },
    {
      href: '/patient/result-pending-appointment',
      label: 'Results Pending Appointments',
      icon: 'clock'
    }
  ]

  if (isGP || isClinician) {
    navItems.push(
      {
        href: '/patient',
        label: 'Patients',
        icon: 'user'
      },
      {
        href: '/patient/review',
        label: 'Results Review',
        icon: 'review'
      }
    )
  }

  if (isResourceManager || isSuperAdmin) {
    // navItems.push({
    //   href: '/shift',
    //   label: 'Shifts',
    //   icon: 'shift'
    // })

    navItems.push(
      {
        href: '/management',
        label: 'Management',
        icon: 'settings'
      },
      {
        href: '/patient/finalizing-results',
        label: 'Finalizing Results',
        icon: 'medical'
      }
    )
  }

  return (
    <Drawer onClose={() => setSidebarOpen(false)} isOpen={sidebarOpen} placement="left" size="xs">
      <DrawerOverlay />
      <DrawerContent
        borderTopEndRadius="xl"
        borderEndEndRadius="xl"
        mt={4}
        maxWidth="var(--sidebar-width)"
      >
        <DrawerHeader>
          <Image src="/assets/qured-logo-full-blue.png" alt="Qured" width={70} height={28} />
          <DrawerCloseButton />
        </DrawerHeader>
        <DrawerBody>
          <nav className={styles.wrapper}>
            <List spacing={6} mt={4} ml={-2}>
              {navItems.map(({ href, label, icon }) => (
                <NavItem key={href} href={href} label={label} icon={icon} />
              ))}
            </List>
          </nav>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
