import { ModalItem } from '@components/Modal/ModalContainer'
import OrderModal from '@components/PatientHeader/OrderModal'
import TagPatientModal from '@components/PatientHeader/TagPatientModal'
import EditPatient from '@components/PatientHeader/EditPatient'

import {
  getPatientName,
  calculateAge,
  findPatientProperty,
  getPatientProperty,
  getPatientIdentifier,
  getPatientOrganization
} from '@utils/patient'
import { BundleEntry, Patient } from 'fhir/r4'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { createContext, ReactNode, FC, useContext } from 'react'
import useSWR from 'swr'
import { useAppContext } from './AppProvider'
import { Source } from './AxiosProvider'
import CreateReferral from '@components/PatientHeader/CreateReferral'

interface PatientDetails {
  id: string
  firstName: string
  middleName: string
  lastName: string
  gender: string
  birthDate: string
  age: number
  phoneNumber: string
  email: string
  city: string
  firebaseUserId: string
  clinicalTags: string[]
  organizationName: string
}

interface CustomAction {
  name: string
  action: () => void
}

interface PatientHeaderContextValues {
  patientDetails: PatientDetails
  defaultActions: string[]
  setCustomActions: (value: CustomAction[]) => void
  customActions: CustomAction[]
  patientID: string
  setPatientID: (value: string) => void
  handleOrderTest: (
    openModal: {
      (modalDetails: ModalItem): void
    },
    patientID: string
  ) => void
  handleTagPatient: (
    openModal: {
      (modalDetails: ModalItem): void
    },
    firebaseUserId: string,
    clinicalTags: string[]
  ) => void
  handleEditPatient: (
    openModal: {
      (modalDetails: ModalItem): void
    },
    firebaseUserId: string
  ) => void
  handleReferPatient: (
    openModal: {
      (modalDetails: ModalItem): void
    },
    patientID: string,
    patientName: string
  ) => void
  refreshPatientData: () => void
  isReferButtonClicked: boolean
  setIsReferButtonClicked: (value: boolean) => void
}

export const PatientHeaderContext = createContext({} as PatientHeaderContextValues)

interface Props {
  children: ReactNode
}

const PatientHeaderProvider: FC<Props> = ({ children }) => {
  const router = useRouter()
  const { isGP, isClinician } = useAppContext()
  const [patientID, setPatientID] = useState<string>('')
  const [customActions, setCustomActions] = useState<CustomAction[]>([])
  const [isReferButtonClicked, setIsReferButtonClicked] = useState(false)
  const { pathname, query } = router

  const defaultActions = []

  useEffect(() => {
    setPatientID('')
  }, [pathname])

  if (pathname === '/patient/[id]') {
    if (patientID === '') {
      setPatientID(query.id as string)
    }
  } else if (pathname === '/appointment/video/[id]') {
    if (patientID === '') {
      setPatientID(query.patientId as string)
    }
  } else if (pathname === '/patient/review/[id]') {
    if (patientID === '') {
      setPatientID(query.patientID as string)
    }
  }

  if (isGP || isClinician) {
    defaultActions.push(...['Order Test', 'Tag Patient', 'Edit Info', 'Create Referral'])
  } else {
    defaultActions.push(...['Edit Info'])
  }

  const { data, mutate: refreshPatientData } = useSWR(
    patientID
      ? [
          `/Patient?_id=${patientID}&_include=Patient:organization&_revinclude=Appointment:patient`,
          Source.FHIR
        ]
      : null
  )

  const patient = data?.entry?.find(
    (entry: BundleEntry) => entry.resource?.resourceType === 'Patient'
  )?.resource as Patient

  const firebaseUserId = getPatientIdentifier(patient, 'PI') as string
  const { data: userData, mutate: refreshUserData } = useSWR([
    `/users?firebaseUserIds=${firebaseUserId}`,
    Source.ACCOUNT
  ])

  let patientDetails = {} as PatientDetails
  let clinicalTags: string[]

  if (patient) {
    const { birthDate, gender } = patient
    const { firstName, middleName, lastName } = getPatientName(patient)
    const age = calculateAge(birthDate as string)
    const mobile = findPatientProperty(patient, 'telecom', ['system', 'phone'])
    const email = findPatientProperty(patient, 'telecom', ['system', 'email'])
    const city = getPatientProperty(patient, 'address[0].city')
    const organizationName = getPatientOrganization(patient)
    clinicalTags = userData?.entry[0]?.resource?.user?.clinicalTags

    patientDetails = {
      id: patientID as string,
      firstName,
      middleName,
      lastName,
      birthDate: birthDate as string,
      gender: gender as string,
      age,
      phoneNumber: mobile,
      email,
      city,
      firebaseUserId,
      clinicalTags,
      organizationName
    }
  }

  const handleOrderTest = (
    openModal: {
      (modalDetails: ModalItem): void
    },
    patientID: string
  ) => {
    openModal({
      header: 'Order Test',
      size: 'xl',
      children: <OrderModal patientID={patientID} />
    })
  }

  const handleTagPatient = (
    openModal: {
      (modalDetails: ModalItem): void
    },
    firebaseUserId: string
  ) => {
    openModal({
      header: 'Tag Patient',
      size: 'xl',
      children: (
        <TagPatientModal
          firebaseUserId={firebaseUserId}
          clinicalTags={clinicalTags}
          refreshPatientData={refreshUserData}
        />
      )
    })
  }

  const handleEditPatient = (
    openModal: {
      (modalDetails: ModalItem): void
    },
    firebaseUserId: string
  ) => {
    openModal({
      header: 'Edit Patient',
      size: 'xl',
      children: (
        <EditPatient
          firebaseUserId={firebaseUserId}
          refreshPatientData={refreshPatientData}
          refreshUserData={refreshUserData}
          employeeData={userData}
        />
      )
    })
  }

  const handleReferPatient = (
    openModal: {
      (modalDetails: ModalItem): void
    },
    patientID: string,
    patientName: string
  ) => {
    openModal({
      header: 'Create Referral',
      size: 'xl',
      children: (
        <CreateReferral
          patientID={patientID}
          patientName={patientName}
          setIsReferButtonClicked={setIsReferButtonClicked}
        />
      )
    })
  }

  const providerValues = {
    patientDetails,
    defaultActions,
    customActions,
    setCustomActions,
    patientID,
    setPatientID,
    handleOrderTest,
    handleTagPatient,
    handleEditPatient,
    handleReferPatient,
    refreshPatientData: refreshUserData,
    isReferButtonClicked,
    setIsReferButtonClicked
  }

  return (
    <PatientHeaderContext.Provider value={providerValues}>{children}</PatientHeaderContext.Provider>
  )
}

const usePatientHeaderProvider = () => useContext(PatientHeaderContext)

export { PatientHeaderProvider, usePatientHeaderProvider }
